import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextAvabots = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="2345"
        height="578"
        viewBox="0 0 2345 578"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M477.559 266.875L474.824 214.238H453.77L451.035 266.875H398.125L423.555 2.32422H506.133L530.469 266.875H477.559ZM456.094 173.086H472.91L468.125 82.5781L465.801 49.082H462.793L460.879 82.5781L456.094 173.086Z"
          fill="#060534"
        />
        <path
          d="M598.965 2.32422H651.055L626.035 266.875H547.969L523.086 2.32422H575.723L583.789 163.242L586.387 209.727H589.668L591.992 163.242L598.965 2.32422Z"
          fill="#060534"
        />
        <path
          d="M723.105 266.875L720.371 214.238H699.316L696.582 266.875H643.672L669.102 2.32422H751.68L776.016 266.875H723.105ZM701.641 173.086H718.457L713.672 82.5781L711.348 49.082H708.34L706.426 82.5781L701.641 173.086Z"
          fill="#060534"
        />
        <path
          d="M876.23 131.934C894.186 136.764 903.164 149.069 903.164 168.848V221.211C903.164 235.43 898.561 246.595 889.355 254.707C880.241 262.819 867.298 266.875 850.527 266.875H782.715V2.32422H852.715C867.48 2.32422 879.238 5.83333 887.988 12.8516C896.829 19.7786 901.25 30.0781 901.25 43.75V93.7891C901.25 113.385 892.91 125.553 876.23 130.293V131.934ZM841.23 42.9297H833.984V114.023H841.504C847.702 114.023 850.801 110.56 850.801 103.633V53.457C850.801 46.4388 847.611 42.9297 841.23 42.9297ZM840.684 149.57H833.984V226.27H840.684C848.158 226.27 851.895 222.077 851.895 213.691V161.875C851.895 153.672 848.158 149.57 840.684 149.57Z"
          fill="#060534"
        />
        <path
          d="M973.848 0C994.173 0 1009.44 5.19531 1019.65 15.5859C1029.86 25.9766 1034.96 42.0638 1034.96 63.8477V205.215C1034.96 247.962 1014.59 269.336 973.848 269.336C933.561 269.336 913.418 247.962 913.418 205.215V63.8477C913.418 21.2826 933.561 0 973.848 0ZM982.598 217.93V51.543C982.598 44.6159 979.772 41.1523 974.121 41.1523C968.561 41.1523 965.781 44.6159 965.781 51.543V217.93C965.781 224.857 968.561 228.32 974.121 228.32C979.772 228.32 982.598 224.857 982.598 217.93Z"
          fill="#060534"
        />
        <path
          d="M1150.49 2.32422V45.3906H1121.37V266.875H1070.1V45.3906H1040.7V2.32422H1150.49Z"
          fill="#060534"
        />
        <path
          d="M1273.81 266.875L1271.07 214.238H1250.02L1247.29 266.875H1194.38L1219.8 2.32422H1302.38L1326.72 266.875H1273.81ZM1252.34 173.086H1269.16L1264.38 82.5781L1262.05 49.082H1259.04L1257.13 82.5781L1252.34 173.086Z"
          fill="#060534"
        />
        <path
          d="M1395.21 2.32422H1447.3L1422.29 266.875H1344.22L1319.34 2.32422H1371.97L1380.04 163.242L1382.64 209.727H1385.92L1388.24 163.242L1395.21 2.32422Z"
          fill="#060534"
        />
        <path
          d="M1519.36 266.875L1516.62 214.238H1495.57L1492.83 266.875H1439.92L1465.35 2.32422H1547.93L1572.27 266.875H1519.36ZM1497.89 173.086H1514.71L1509.92 82.5781L1507.6 49.082H1504.59L1502.68 82.5781L1497.89 173.086Z"
          fill="#060534"
        />
        <path
          d="M1672.48 131.934C1690.44 136.764 1699.41 149.069 1699.41 168.848V221.211C1699.41 235.43 1694.81 246.595 1685.61 254.707C1676.49 262.819 1663.55 266.875 1646.78 266.875H1578.96V2.32422H1648.96C1663.73 2.32422 1675.49 5.83333 1684.24 12.8516C1693.08 19.7786 1697.5 30.0781 1697.5 43.75V93.7891C1697.5 113.385 1689.16 125.553 1672.48 130.293V131.934ZM1637.48 42.9297H1630.23V114.023H1637.75C1643.95 114.023 1647.05 110.56 1647.05 103.633V53.457C1647.05 46.4388 1643.86 42.9297 1637.48 42.9297ZM1636.93 149.57H1630.23V226.27H1636.93C1644.41 226.27 1648.14 222.077 1648.14 213.691V161.875C1648.14 153.672 1644.41 149.57 1636.93 149.57Z"
          fill="#060534"
        />
        <path
          d="M1770.1 0C1790.42 0 1805.69 5.19531 1815.9 15.5859C1826.11 25.9766 1831.21 42.0638 1831.21 63.8477V205.215C1831.21 247.962 1810.84 269.336 1770.1 269.336C1729.81 269.336 1709.67 247.962 1709.67 205.215V63.8477C1709.67 21.2826 1729.81 0 1770.1 0ZM1778.85 217.93V51.543C1778.85 44.6159 1776.02 41.1523 1770.37 41.1523C1764.81 41.1523 1762.03 44.6159 1762.03 51.543V217.93C1762.03 224.857 1764.81 228.32 1770.37 228.32C1776.02 228.32 1778.85 224.857 1778.85 217.93Z"
          fill="#060534"
        />
        <path
          d="M1946.74 2.32422V45.3906H1917.62V266.875H1866.35V45.3906H1836.95V2.32422H1946.74Z"
          fill="#060534"
        />
        <path
          d="M79.4336 574.875L76.6992 522.238H55.6445L52.9102 574.875H0L25.4297 310.324H108.008L132.344 574.875H79.4336ZM57.9688 481.086H74.7852L70 390.578L67.6758 357.082H64.668L62.7539 390.578L57.9688 481.086Z"
          fill="#060534"
        />
        <path
          d="M200.84 310.324H252.93L227.91 574.875H149.844L124.961 310.324H177.598L185.664 471.242L188.262 517.727H191.543L193.867 471.242L200.84 310.324Z"
          fill="#060534"
        />
        <path
          d="M324.98 574.875L322.246 522.238H301.191L298.457 574.875H245.547L270.977 310.324H353.555L377.891 574.875H324.98ZM303.516 481.086H320.332L315.547 390.578L313.223 357.082H310.215L308.301 390.578L303.516 481.086Z"
          fill="#060534"
        />
        <path
          d="M478.105 439.934C496.061 444.764 505.039 457.069 505.039 476.848V529.211C505.039 543.43 500.436 554.595 491.23 562.707C482.116 570.819 469.173 574.875 452.402 574.875H384.59V310.324H454.59C469.355 310.324 481.113 313.833 489.863 320.852C498.704 327.779 503.125 338.078 503.125 351.75V401.789C503.125 421.385 494.785 433.553 478.105 438.293V439.934ZM443.105 350.93H435.859V422.023H443.379C449.577 422.023 452.676 418.56 452.676 411.633V361.457C452.676 354.439 449.486 350.93 443.105 350.93ZM442.559 457.57H435.859V534.27H442.559C450.033 534.27 453.77 530.077 453.77 521.691V469.875C453.77 461.672 450.033 457.57 442.559 457.57Z"
          fill="#060534"
        />
        <path
          d="M575.723 308C596.048 308 611.315 313.195 621.523 323.586C631.732 333.977 636.836 350.064 636.836 371.848V513.215C636.836 555.962 616.465 577.336 575.723 577.336C535.436 577.336 515.293 555.962 515.293 513.215V371.848C515.293 329.283 535.436 308 575.723 308ZM584.473 525.93V359.543C584.473 352.616 581.647 349.152 575.996 349.152C570.436 349.152 567.656 352.616 567.656 359.543V525.93C567.656 532.857 570.436 536.32 575.996 536.32C581.647 536.32 584.473 532.857 584.473 525.93Z"
          fill="#060534"
        />
        <path
          d="M752.363 310.324V353.391H723.242V574.875H671.973V353.391H642.578V310.324H752.363Z"
          fill="#060534"
        />
        <path
          d="M875.684 574.875L872.949 522.238H851.895L849.16 574.875H796.25L821.68 310.324H904.258L928.594 574.875H875.684ZM854.219 481.086H871.035L866.25 390.578L863.926 357.082H860.918L859.004 390.578L854.219 481.086Z"
          fill="#060534"
        />
        <path
          d="M997.09 310.324H1049.18L1024.16 574.875H946.094L921.211 310.324H973.848L981.914 471.242L984.512 517.727H987.793L990.117 471.242L997.09 310.324Z"
          fill="#060534"
        />
        <path
          d="M1121.23 574.875L1118.5 522.238H1097.44L1094.71 574.875H1041.8L1067.23 310.324H1149.8L1174.14 574.875H1121.23ZM1099.77 481.086H1116.58L1111.8 390.578L1109.47 357.082H1106.46L1104.55 390.578L1099.77 481.086Z"
          fill="#060534"
        />
        <path
          d="M1274.36 439.934C1292.31 444.764 1301.29 457.069 1301.29 476.848V529.211C1301.29 543.43 1296.69 554.595 1287.48 562.707C1278.37 570.819 1265.42 574.875 1248.65 574.875H1180.84V310.324H1250.84C1265.61 310.324 1277.36 313.833 1286.11 320.852C1294.95 327.779 1299.38 338.078 1299.38 351.75V401.789C1299.38 421.385 1291.04 433.553 1274.36 438.293V439.934ZM1239.36 350.93H1232.11V422.023H1239.63C1245.83 422.023 1248.93 418.56 1248.93 411.633V361.457C1248.93 354.439 1245.74 350.93 1239.36 350.93ZM1238.81 457.57H1232.11V534.27H1238.81C1246.28 534.27 1250.02 530.077 1250.02 521.691V469.875C1250.02 461.672 1246.28 457.57 1238.81 457.57Z"
          fill="#060534"
        />
        <path
          d="M1371.97 308C1392.3 308 1407.57 313.195 1417.77 323.586C1427.98 333.977 1433.09 350.064 1433.09 371.848V513.215C1433.09 555.962 1412.71 577.336 1371.97 577.336C1331.69 577.336 1311.54 555.962 1311.54 513.215V371.848C1311.54 329.283 1331.69 308 1371.97 308ZM1380.72 525.93V359.543C1380.72 352.616 1377.9 349.152 1372.25 349.152C1366.69 349.152 1363.91 352.616 1363.91 359.543V525.93C1363.91 532.857 1366.69 536.32 1372.25 536.32C1377.9 536.32 1380.72 532.857 1380.72 525.93Z"
          fill="#060534"
        />
        <path
          d="M1548.61 310.324V353.391H1519.49V574.875H1468.22V353.391H1438.83V310.324H1548.61Z"
          fill="#060534"
        />
        <path
          d="M1671.93 574.875L1669.2 522.238H1648.14L1645.41 574.875H1592.5L1617.93 310.324H1700.51L1724.84 574.875H1671.93ZM1650.47 481.086H1667.29L1662.5 390.578L1660.18 357.082H1657.17L1655.25 390.578L1650.47 481.086Z"
          fill="#060534"
        />
        <path
          d="M1793.34 310.324H1845.43L1820.41 574.875H1742.34L1717.46 310.324H1770.1L1778.16 471.242L1780.76 517.727H1784.04L1786.37 471.242L1793.34 310.324Z"
          fill="#060534"
        />
        <path
          d="M1917.48 574.875L1914.75 522.238H1893.69L1890.96 574.875H1838.05L1863.48 310.324H1946.05L1970.39 574.875H1917.48ZM1896.02 481.086H1912.83L1908.05 390.578L1905.72 357.082H1902.71L1900.8 390.578L1896.02 481.086Z"
          fill="#060534"
        />
        <path
          d="M2070.61 439.934C2088.56 444.764 2097.54 457.069 2097.54 476.848V529.211C2097.54 543.43 2092.94 554.595 2083.73 562.707C2074.62 570.819 2061.67 574.875 2044.9 574.875H1977.09V310.324H2047.09C2061.86 310.324 2073.61 313.833 2082.36 320.852C2091.2 327.779 2095.62 338.078 2095.62 351.75V401.789C2095.62 421.385 2087.29 433.553 2070.61 438.293V439.934ZM2035.61 350.93H2028.36V422.023H2035.88C2042.08 422.023 2045.18 418.56 2045.18 411.633V361.457C2045.18 354.439 2041.99 350.93 2035.61 350.93ZM2035.06 457.57H2028.36V534.27H2035.06C2042.53 534.27 2046.27 530.077 2046.27 521.691V469.875C2046.27 461.672 2042.53 457.57 2035.06 457.57Z"
          fill="#060534"
        />
        <path
          d="M2168.22 308C2188.55 308 2203.82 313.195 2214.02 323.586C2224.23 333.977 2229.34 350.064 2229.34 371.848V513.215C2229.34 555.962 2208.96 577.336 2168.22 577.336C2127.94 577.336 2107.79 555.962 2107.79 513.215V371.848C2107.79 329.283 2127.94 308 2168.22 308ZM2176.97 525.93V359.543C2176.97 352.616 2174.15 349.152 2168.5 349.152C2162.94 349.152 2160.16 352.616 2160.16 359.543V525.93C2160.16 532.857 2162.94 536.32 2168.5 536.32C2174.15 536.32 2176.97 532.857 2176.97 525.93Z"
          fill="#060534"
        />
        <path
          d="M2344.86 310.324V353.391H2315.74V574.875H2264.47V353.391H2235.08V310.324H2344.86Z"
          fill="#060534"
        />
      </svg>
    );
  },
);
