import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconMenu = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="3.5" width="20" height="3" fill="white" />
        <rect x="2" y="10.5" width="20" height="3" fill="white" />
        <rect x="2" y="17.5" width="20" height="3" fill="white" />
      </svg>
    );
  },
);
