export const getReferralCode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('ref') || null;
};

export const openTelegramWithRef = (refCode?: string | null) => {
  let url = `https://t.me/AvaSniperBot`;

  if (!!refCode) {
    url = `https://t.me/AvaSniperBot?start=ref${refCode}`;
  }
  return window?.open(url, '_blank');
};
