import {
  NextSeoProps,
} from 'next-seo';
import { MetaTag, OpenGraph } from 'next-seo/lib/types';

type SocialMedia = {
  url: string;
  icon: string;
};

interface ILinkTag {
  rel: string;
  href: string;
  sizes?: string;
  media?: string;
  type?: string;
  color?: string;
  keyOverride?: string;
  as?: string;
  crossOrigin?: string;
}

interface ISeoConfigs {
  title: string;
  description: string;
  siteName: string;
  logo: string;
  url: string;
  contact: {
    phone: {
      number: string;
      alias: string;
    };
    email: string;
    website: string;
    address: string;
  };
  socialMedia: {
    twitter: SocialMedia;
    facebook: SocialMedia;
    telegram: SocialMedia;
    linkedin: SocialMedia;
  };
  organization: {
    type: string;
    name: string;
    legalName: string;
  };
  openGraph?: OpenGraph;
  metaTags?: ReadonlyArray<MetaTag>;
  linkTags?: ReadonlyArray<ILinkTag>;
}

export const seoConfigs: ISeoConfigs = {
  title: 'Ava Bot - Trustless Account Abstraction Sniper Bot',
  description: 'Meet Ava, your trustless Account Abstraction Telegram trading bot. Method Sniper, Call channels, Private tx, Copy Trade, Limit Orders and more.',
  siteName: 'Ava Bot - Trustless Account Abstraction Sniper Bot',
  logo: '/favicon.ico',
  url: 'https://avabot.ai',
  contact: {
    phone: {
      number: '',
      alias: ''
    },
    email: '',
    website: '',
    address: ''
  },
  socialMedia: {
    twitter: {
      url: '',
      icon: ''
    },
    facebook: {
      url: '',
      icon: ''
    },
    linkedin: {
      url: '',
      icon: ''
    },
    telegram: {
      url: '',
      icon: ''
    }
  },
  organization: {
    type: 'Corporation',
    name: 'Sota Labs',
    legalName: 'SOTA LABS TECHNOLOGY JOINT STOCK COMPANY'
  },
  metaTags: [
    {
      name: 'msapplication-TileColor',
      content: '#da532c'
    },
    {
      name: 'theme-color',
      content: '#ffffff'
    },
    {
      httpEquiv: 'x-ua-compatible',
      content: 'IE=edge'
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:domain',
      content: 'avabot.ai',
    },
    {
      property: 'twitter:url',
      content: 'https://avabot.ai'
    },
    {
      name: 'twitter:title',
      content: 'Ava Bot - Trustless Account Abstraction Sniper Bot',
    },
    {
      name: 'twitter:description',
      content: 'Meet Ava, your trustless Account Abstraction Telegram trading bot. Method Sniper, Call channels, Private tx, Copy Trade, Limit Orders and more.',
    },
    {
      name: 'twitter:image',
      content: 'https://avabot.ai/opengraph.png',
    }
  ],
  linkTags: [
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
      type: 'image/x-icon'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest'
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg'
    },
  ]
};

export const defaultSEO: NextSeoProps = {
  title: seoConfigs.title,
  description: seoConfigs.description,
  openGraph: {
    type: 'website',
    url: 'https://avabot.ai',
    site_name: seoConfigs.siteName,
    title: seoConfigs.title,
    description: seoConfigs.description,
    images: [
      {
        url: 'https://avabot.ai/opengraph.png',
        width: 1200,
        height: 630,
        alt: 'Image Avabot',
      }
    ],
  },
  canonical: seoConfigs.url,
  additionalMetaTags: seoConfigs.metaTags,
  additionalLinkTags: seoConfigs.linkTags,
};

