import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconArrowLeft = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.77532 4.55806C8.53124 4.31398 8.13551 4.31398 7.89143 4.55806L2.89143 9.55806C2.64735 9.80214 2.64735 10.1979 2.89143 10.4419L7.89143 15.4419C8.13551 15.686 8.53124 15.686 8.77532 15.4419C9.01939 15.1979 9.01939 14.8021 8.77532 14.5581L4.84226 10.625H16.6667C17.0119 10.625 17.2917 10.3452 17.2917 10C17.2917 9.65482 17.0119 9.375 16.6667 9.375H4.84226L8.77532 5.44194C9.01939 5.19786 9.01939 4.80214 8.77532 4.55806Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
