import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconArrow = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.15393 4.2216L18.6533 4.2216L19.7783 5.3466V18.846H17.5283V8.06261L6.27898 19.312L4.68799 17.721L15.9373 6.4716L5.15393 6.4716V4.2216Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
