import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconCategory = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M8.65312 21.1075L9.31113 13.9741L3.95381 18.6976L0.995193 14.1514L7.25952 11.0728L0.717542 8.85346L3.18474 4.02288L8.97078 7.92729L7.6064 1.13493L13.0322 0.850576L12.565 7.59789L17.7315 3.26052L20.6901 7.80674L14.3926 10.699L20.9678 13.1046L18.5006 17.9352L12.8746 13.9519L14.0789 20.8232L8.65312 21.1075Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
