import React, { FC } from 'react';
import {
  IconLogoFooter,
  IconTelegram,
  IconTwitter,
  IconEmail,
  TextAvabot,
} from 'components/icons';
import Image from 'next/image';
import Link from 'next/link';
const { Rotate } = require('react-reveal');

const MENUS = [
  {
    name: 'AVA Manual',
    path: 'https://docs.avabot.ai/',
  },
  {
    name: 'Start trading',
    path: '#',
  },
  {
    name: 'Terms and Conditions',
    path: '#',
  },
  {
    name: 'Referral Program',
    path: '#',
  },
  {
    name: 'Brand Kit',
    path: 'https://avabot.notion.site/ef5591c9c92b4532b66d1716b6b4ae0b?v=deec44496bfd4a469ff237264cffe36b&pvs=4',
  },
];

export const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer__container">
          <div className="footer__content">
            <div className="footer__content--top">
              <div className="footer__title">
                <div>Ready to</div>
                <div className="footer__brand">
                  MEET AVA
                  <Rotate top left opposite>
                    <Image
                      width={192}
                      height={72}
                      src="/images/now-available.png"
                      alt="now-available"
                    />
                  </Rotate>
                </div>
                <div>on TELEGRAM</div>
              </div>

              <div className="footer__contact">
                <div className="footer__contact-contain">
                  <Link
                    href="https://t.me/GmAvabot_NoKeysNoWorries"
                    target="_blank"
                    rel="noreferrer"
                    title="Telegram"
                  >
                    <div className="footer__contact-item telegram">
                      <IconTelegram />
                      TELEGRAM
                    </div>
                  </Link>
                  <Link
                    href="https://twitter.com/GmAvaBot"
                    target="_blank"
                    rel="noreferrer"
                    title="Twitter"
                  >
                    <div className="footer__contact-item twitter">
                      <IconTwitter />
                      TWITTER
                    </div>
                  </Link>

                  <Link
                    href="mailto:gm@avabot.ai"
                    target="_blank"
                    rel="noreferrer"
                    title="Email"
                  >
                    <div className="footer__contact-item email">
                      <IconEmail />
                      EMAIL
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="footer__menu desktop">
              <div className="footer__logo">
                <IconLogoFooter />
              </div>

              {MENUS.map((item, index) => {
                return (
                  <Link
                    key={index}
                    href={item.path}
                    target={'_blank'}
                    rel="noreferrer"
                    title={item.name}
                  >
                    <div className="footer__menu-item">
                      {item.name}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <div className="footer__text">
          <TextAvabot />
        </div>
      </div>
    </div>
  );
};
