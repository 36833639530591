import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextBlogMedium = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="610"
        height="388"
        viewBox="0 0 610 388"
        fill="none"
      >
        <g opacity="0.1">
          <path
            d="M54.2912 190.074C80.0695 197.01 92.9587 214.675 92.9587 243.071V318.247C92.9587 338.66 86.3506 354.69 73.1342 366.336C60.0488 377.982 41.4674 383.805 17.3901 383.805H-79.9658V3.99891H20.5306C41.7291 3.99891 58.6093 9.03681 71.1714 19.1126C83.8643 29.0576 90.2108 43.8442 90.2108 63.4724V135.312C90.2108 163.445 78.2376 180.915 54.2912 187.719V190.074ZM4.04292 62.2947H-6.36003V164.361H4.43549C13.3336 164.361 17.7827 159.389 17.7827 149.444V77.4084C17.7827 67.3326 13.2028 62.2947 4.04292 62.2947ZM3.2578 215.395H-6.36003V325.509H3.2578C13.9879 325.509 19.3529 319.49 19.3529 307.451V233.06C19.3529 221.283 13.9879 215.395 3.2578 215.395Z"
            fill="#FF4DC0"
          />
          <path
            d="M183.641 321.976H238.796V383.805H110.035V3.99891H183.641V321.976Z"
            fill="#FF4DC0"
          />
          <path
            d="M332.227 0.662109C361.407 0.662109 383.325 8.12083 397.981 23.0383C412.637 37.9557 419.965 61.0516 419.965 92.3259V295.282C419.965 356.652 390.719 387.338 332.227 387.338C274.389 387.338 245.47 356.652 245.47 295.282V92.3259C245.47 31.2167 274.389 0.662109 332.227 0.662109ZM344.789 313.536V74.6605C344.789 64.7155 340.732 59.743 332.619 59.743C324.637 59.743 320.646 64.7155 320.646 74.6605V313.536C320.646 323.481 324.637 328.453 332.619 328.453C340.732 328.453 344.789 323.481 344.789 313.536Z"
            fill="#FF4DC0"
          />
          <path
            d="M541.856 350.829C538.061 363.915 532.565 373.271 525.368 378.898C518.171 384.525 508.03 387.338 494.945 387.338C477.802 387.338 464.128 380.991 453.922 368.299C443.715 355.606 438.612 336.632 438.612 311.377V92.3259C438.612 60.9207 445.874 37.8249 460.399 23.0383C475.055 8.12083 497.3 0.662109 527.135 0.662109C554.222 0.662109 574.766 6.94314 588.767 19.5052C602.9 31.9364 609.966 50.3215 609.966 74.6605V161.417H536.36V74.6605C536.36 64.7155 532.304 59.743 524.191 59.743C516.47 59.743 512.61 64.7155 512.61 74.6605V314.714C512.61 324.658 516.732 329.631 524.976 329.631C528.77 329.631 531.78 328.257 534.005 325.509C536.36 322.63 537.538 318.901 537.538 314.321V227.761H525.957V179.279H609.966V383.805H552.455L545.585 350.829H541.856Z"
            fill="#FF4DC0"
          />
        </g>
      </svg>
    );
  },
);
