import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconMenuActive = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5.98962"
          y="3.86829"
          width="20"
          height="3"
          transform="rotate(45 5.98962 3.86829)"
          fill="white"
        />
        <rect
          x="3.86829"
          y="18.0104"
          width="20"
          height="3"
          transform="rotate(-45 3.86829 18.0104)"
          fill="white"
        />
      </svg>
    );
  },
);
