import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconLogo = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="116"
        height="40"
        viewBox="0 0 116 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.3432 15.4724C49.1141 15.4724 51.2651 17.2782 51.2651 20.9163V29.4938H47.3349V27.5818C46.5913 28.9096 45.1308 29.7328 42.8735 29.7328C40.3773 29.7328 38.3325 28.5643 38.3325 25.9353C38.3325 23.2798 40.218 22.0848 43.2453 21.713L47.3349 21.2084V20.757C47.3349 19.6416 46.7506 18.6591 45.0776 18.6591C43.5905 18.6591 42.7939 19.5089 42.4752 20.7835L38.6512 20.1462C39.3416 17.2782 41.5989 15.4724 45.3432 15.4724ZM44.1482 26.7586C45.8478 26.7586 47.3349 25.9088 47.3349 24.2092V23.8109L43.9623 24.2889C42.9001 24.4482 42.3424 24.8466 42.3424 25.5901C42.3424 26.3337 42.9532 26.7586 44.1482 26.7586Z"
          fill="white"
        />
        <path
          d="M61.4498 16.455L61.7154 15.738H65.9643L60.839 29.4938H56.1918L51.0931 15.738H55.4217L55.7138 16.5081L58.6084 24.82L61.4498 16.455Z"
          fill="white"
        />
        <path
          d="M72.1021 15.4724C75.873 15.4724 78.024 17.2782 78.024 20.9163V29.4938H74.0938V27.5818C73.3502 28.9096 71.8897 29.7328 69.6325 29.7328C67.1362 29.7328 65.0914 28.5643 65.0914 25.9353C65.0914 23.2798 66.9769 22.0848 70.0042 21.713L74.0938 21.2084V20.757C74.0938 19.6416 73.5096 18.6591 71.8366 18.6591C70.3495 18.6591 69.5528 19.5089 69.2341 20.7835L65.4101 20.1462C66.1006 17.2782 68.3578 15.4724 72.1021 15.4724ZM70.9071 26.7586C72.6067 26.7586 74.0938 25.9088 74.0938 24.2092V23.8109L70.7212 24.2889C69.659 24.4482 69.1013 24.8466 69.1013 25.5901C69.1013 26.3337 69.7121 26.7586 70.9071 26.7586Z"
          fill="white"
        />
        <path
          d="M87.3818 15.6583C90.9402 15.6583 93.3302 18.3404 93.3302 22.669C93.3302 26.8648 90.9402 29.6797 87.1428 29.6797C84.9918 29.6797 83.2125 28.644 82.2565 26.8913V29.4938H80.1321V10.2675H82.2565V18.5794C83.2656 16.7736 85.1511 15.6583 87.3818 15.6583ZM86.771 27.688C89.4531 27.688 91.1792 25.7494 91.1792 22.669C91.1792 19.6682 89.5062 17.65 86.7975 17.65C84.142 17.65 82.3096 19.5885 82.3096 22.669C82.3096 25.6698 84.0358 27.688 86.771 27.688Z"
          fill="white"
        />
        <path
          d="M101.169 29.6797C97.2123 29.6797 94.4505 26.971 94.4505 22.669C94.4505 18.3935 97.2123 15.6583 101.169 15.6583C105.152 15.6583 107.914 18.4201 107.914 22.669C107.914 26.971 105.152 29.6797 101.169 29.6797ZM101.169 27.688C103.878 27.688 105.763 25.8291 105.763 22.669C105.763 19.5354 103.851 17.65 101.169 17.65C98.5135 17.65 96.6015 19.5354 96.6015 22.669C96.6015 25.8291 98.5135 27.688 101.169 27.688Z"
          fill="white"
        />
        <path
          d="M115.877 29.4938H114.072C111.628 29.4938 110.513 28.4847 110.513 26.015V17.7296L108.123 17.7562V15.8708L109.637 15.8973C110.407 15.8973 110.699 15.6583 110.699 14.8882V11.914H112.585V15.8973L115.798 15.8708V17.7562L112.585 17.7296V26.3337C112.585 27.0772 112.877 27.4224 113.726 27.4224H115.877V29.4938Z"
          fill="white"
        />
        <path
          d="M31.1896 19.6223V35.9121L26.3747 30.8621V19.6223C26.3747 13.7048 21.5772 8.90741 15.6573 8.90741C9.73738 8.90741 4.94244 13.7048 4.94244 19.6223C4.94244 25.5397 9.73988 30.3396 15.6573 30.3396C18.5248 30.3396 21.1297 29.2147 23.0522 27.3797L26.3747 30.8621C23.5872 33.5196 19.8148 35.1571 15.6573 35.1571C7.07991 35.1571 0.125 28.2022 0.125 19.6223C0.125 11.0424 7.07991 4.08997 15.6573 4.08997C24.2347 4.08997 31.1896 11.0449 31.1896 19.6223Z"
          fill="white"
        />
        <path
          d="M9.15824 17.9042C9.15824 16.8092 10.0459 15.9215 11.1409 15.9215C12.2359 15.9215 13.1236 16.8092 13.1236 17.9042V19.6223C13.1236 20.7173 12.2359 21.605 11.1409 21.605C10.0459 21.605 9.15824 20.7173 9.15824 19.6223V17.9042Z"
          fill="white"
        />
        <path
          d="M18.602 15.9215C17.507 15.9215 16.6193 16.8092 16.6193 17.9042V19.6223C16.6193 20.7173 17.507 21.605 18.602 21.605C19.697 21.605 20.5846 20.7173 20.5846 19.6223V17.9042C20.5846 16.8092 19.697 15.9215 18.602 15.9215Z"
          fill="white"
        />
      </svg>
    );
  },
);
