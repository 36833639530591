import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconLinkShare = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14.8283 12.0011L16.2426 13.4153L19.071 10.5869C20.6331 9.02475 20.6331 6.49209 19.071 4.93C17.5089 3.3679 14.9762 3.3679 13.4141 4.93L10.5857 7.75842L11.9999 9.17264L14.8283 6.34421C15.6094 5.56316 16.8757 5.56316 17.6568 6.34421C18.4378 7.12526 18.4378 8.39159 17.6568 9.17264L14.8283 12.0011Z"
          fill="#060534"
        />
        <path
          d="M12 14.8296L13.4142 16.2438L10.5858 19.0722C9.02365 20.6343 6.49099 20.6343 4.9289 19.0722C3.3668 17.5101 3.3668 14.9775 4.9289 13.4154L7.75732 10.5869L9.17154 12.0011L6.34311 14.8296C5.56206 15.6106 5.56206 16.8769 6.34311 17.658C7.12416 18.439 8.39049 18.439 9.17154 17.658L12 14.8296Z"
          fill="#060534"
        />
        <path
          d="M14.8284 10.5868C15.2189 10.1963 15.2189 9.56309 14.8284 9.17257C14.4379 8.78204 13.8047 8.78204 13.4142 9.17257L9.17154 13.4152C8.78101 13.8057 8.78101 14.4389 9.17154 14.8294C9.56206 15.2199 10.1952 15.2199 10.5857 14.8294L14.8284 10.5868Z"
          fill="#060534"
        />
      </svg>
    );
  },
);
