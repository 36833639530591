import React, { useState, useEffect, FC } from 'react';
import {
  IconLogo,
  IconTwitter,
  IconMenu,
  IconMenuActive,
} from 'components/icons';
import { useSearchParams } from 'next/navigation';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const MENUS = [
  {
    name: 'Dashboard',
    path: 'http://dashboard.avabot.ai/',
  },
  {
    name: 'AVA Manual',
    path: 'https://docs.avabot.ai/',
  },
  {
    name: 'Blog',
    path: '/blog',
  },
  {
    name: 'AVA Campaign',
    path: 'https://t.me/AvaCampaignBot',
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/GmAvaBot',
    isMobile: true,
  },
];

interface IHeader {
  handleActionLink: () => void;
}

export const Header: FC<IHeader> = ({ handleActionLink }) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [sticky, setSticky] = useState('');
  const pathname = usePathname();

  const searchParams = useSearchParams();
  const refCode = searchParams.get('ref');

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isOpenMenu]);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 1 ? 'is-sticky' : '';
    setSticky(stickyClass);
  };

  return (
    <div
      className={`header ${isOpenMenu ? 'open' : ''} ${sticky} ${
        pathname?.includes('blog') && pathname !== '/blog' ? 'is-sticky' : ''
      }`}
    >
      <div className="header__content">
        <div className="header__content--left">
          <Link href={'/'} rel="noreferrer" title={'Home'}>
            <div className="header__logo">
              <IconLogo />
            </div>
          </Link>

          <div className="header__menu">
            {MENUS.map((menu, index) => {
              if (menu.isMobile) return;
              return (
                <a
                  key={index}
                  href={menu.path}
                  target={menu.path.includes('blog') ? '' : '_blank'}
                  rel="noreferrer"
                  title={menu.name}
                >
                  <div
                    className={`header__menu-item ${
                      pathname?.includes(menu.name.toLowerCase())
                        ? 'active'
                        : ''
                    }`}
                  >
                    {menu.name}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div
          className={'header__icon-menu'}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          {isOpenMenu ? <IconMenuActive /> : <IconMenu />}
        </div>
        <div className="header__content--right">
          <div className="header__contact">
            <Link
              href="https://twitter.com/GmAvaBot"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <div className="header__contact-item">
                <IconTwitter />
              </div>
            </Link>
          </div>
          <div className="header__btn" onClick={() => handleActionLink()}>
            Start Trading
          </div>
        </div>
      </div>

      {isOpenMenu && (
        <div className="header__menu-mobile">
          {MENUS.map((menu, index) => {
            return (
              <Link
                key={index}
                href={menu.path}
                target={menu.path.includes('blog') ? '_parent' : '_blank'}
                rel="noreferrer"
                title={menu.name}
              >
                <div
                  className={`header__menu-mobile-item ${
                    pathname?.includes(menu.name.toLowerCase()) ? 'active' : ''
                  }`}
                >
                  {menu.name}
                </div>
              </Link>
            );
          })}

          <div
            className="header__btn"
            onClick={() => {
              handleActionLink();
              setIsOpenMenu(false);
            }}
          >
            Start Trading
          </div>
        </div>
      )}
    </div>
  );
};
