import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextBlog = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="984"
        height="621"
        viewBox="0 0 984 621"
        fill="none"
      >
        <g opacity="0.1">
          <path
            d="M92.6069 303.831C133.94 314.951 154.607 343.276 154.607 388.805V509.343C154.607 542.074 144.011 567.776 122.82 586.45C101.839 605.123 72.0452 614.46 33.4394 614.46H-122.662V5.47525H38.4749C72.4648 5.47525 99.5308 13.5531 119.673 29.7088C140.025 45.6546 150.201 69.3636 150.201 100.836V216.023C150.201 261.133 131.003 289.144 92.6069 300.054V303.831ZM12.0384 98.9473H-4.64181V262.602H12.6678C26.9352 262.602 34.0688 254.629 34.0688 238.683V123.181C34.0688 107.025 26.7254 98.9473 12.0384 98.9473ZM10.7795 344.43H-4.64181V520.988H10.7795C27.9843 520.988 36.5866 511.336 36.5866 492.034V372.754C36.5866 353.871 27.9843 344.43 10.7795 344.43Z"
            fill="#FF4DC0"
          />
          <path
            d="M300.008 515.323H388.444V614.46H181.988V5.47525H300.008V515.323Z"
            fill="#FF4DC0"
          />
          <path
            d="M538.252 0.125C585.04 0.125 620.184 12.0844 643.683 36.0032C667.182 59.922 678.932 96.9541 678.932 147.1V472.521C678.932 570.924 632.038 620.125 538.252 620.125C445.514 620.125 399.145 570.924 399.145 472.521V147.1C399.145 49.1165 445.514 0.125 538.252 0.125ZM558.394 501.79V118.775C558.394 102.829 551.889 94.856 538.881 94.856C526.082 94.856 519.683 102.829 519.683 118.775V501.79C519.683 517.736 526.082 525.709 538.881 525.709C551.889 525.709 558.394 517.736 558.394 501.79Z"
            fill="#FF4DC0"
          />
          <path
            d="M874.373 561.587C868.289 582.568 859.477 597.57 847.937 606.592C836.397 615.614 820.136 620.125 799.155 620.125C771.669 620.125 749.744 609.949 733.378 589.597C717.013 569.245 708.83 538.822 708.83 498.328V147.1C708.83 96.7443 720.475 59.7121 743.764 36.0032C767.263 12.0844 802.932 0.125 850.769 0.125C894.201 0.125 927.142 10.1961 949.592 30.3382C972.252 50.2705 983.582 79.7494 983.582 118.775V257.881H865.561V118.775C865.561 102.829 859.057 94.856 846.049 94.856C833.669 94.856 827.48 102.829 827.48 118.775V503.678C827.48 519.624 834.089 527.597 847.307 527.597C853.392 527.597 858.218 525.394 861.785 520.988C865.561 516.372 867.45 510.392 867.45 503.049V364.257H848.881V286.521H983.582V614.46H891.368L880.353 561.587H874.373Z"
            fill="#FF4DC0"
          />
        </g>
      </svg>
    );
  },
);
