import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextAVA = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="1562"
        height="756"
        viewBox="0 0 1562 756"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.1">
          <path
            d="M323.991 786L312.655 622.336H225.367L214.031 786H-5.32178L100.104 -36.573H442.453L543.344 786H323.991ZM235.003 494.38H304.72L284.881 212.962L275.246 108.812H262.776L254.841 212.962L235.003 494.38Z"
            fill="#FF4DC0"
          />
          <path
            d="M827.312 -36.573H1043.26L939.539 786H615.894L512.736 -36.573H730.955L764.397 463.772L775.166 608.307H788.769L798.405 463.772L827.312 -36.573Z"
            fill="#FF4DC0"
          />
          <path
            d="M1341.97 786L1330.63 622.336H1243.35L1232.01 786H1012.66L1118.08 -36.573H1460.43L1561.32 786H1341.97ZM1252.98 494.38H1322.7L1302.86 212.962L1293.22 108.812H1280.75L1272.82 212.962L1252.98 494.38Z"
            fill="#FF4DC0"
          />
        </g>
      </svg>
    );
  },
);
