import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconLogoFooter = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="58"
        height="20"
        viewBox="0 0 58 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.625 7.6238C24.5118 7.6238 25.588 8.52733 25.588 10.3477V14.6394H23.6215V13.6827C23.2495 14.3471 22.5187 14.759 21.3893 14.759C20.1403 14.759 19.1172 14.1743 19.1172 12.8589C19.1172 11.5302 20.0606 10.9323 21.5753 10.7463L23.6215 10.4938V10.2679C23.6215 9.70988 23.3292 9.21826 22.4921 9.21826C21.748 9.21826 21.3494 9.64344 21.19 10.2812L19.2766 9.96233C19.6221 8.52733 20.7515 7.6238 22.625 7.6238ZM22.0271 13.2708C22.8774 13.2708 23.6215 12.8456 23.6215 11.9953V11.796L21.9341 12.0351C21.4026 12.1148 21.1235 12.3142 21.1235 12.6862C21.1235 13.0582 21.4291 13.2708 22.0271 13.2708Z"
          fill="white"
        />
        <path
          d="M30.6839 8.11543L30.8168 7.75668H32.9427L30.3783 14.6394H28.0531L25.502 7.75668H27.6678L27.8139 8.142L29.2622 12.3009L30.6839 8.11543Z"
          fill="white"
        />
        <path
          d="M36.0138 7.6238C37.9006 7.6238 38.9768 8.52733 38.9768 10.3477V14.6394H37.0103V13.6827C36.6383 14.3471 35.9075 14.759 34.7781 14.759C33.5291 14.759 32.506 14.1743 32.506 12.8589C32.506 11.5302 33.4494 10.9323 34.9641 10.7463L37.0103 10.4938V10.2679C37.0103 9.70988 36.718 9.21826 35.8809 9.21826C35.1369 9.21826 34.7383 9.64344 34.5788 10.2812L32.6655 9.96233C33.0109 8.52733 34.1403 7.6238 36.0138 7.6238ZM35.4159 13.2708C36.2663 13.2708 37.0103 12.8456 37.0103 11.9953V11.796L35.3229 12.0351C34.7914 12.1148 34.5124 12.3142 34.5124 12.6862C34.5124 13.0582 34.818 13.2708 35.4159 13.2708Z"
          fill="white"
        />
        <path
          d="M43.659 7.71681C45.4395 7.71681 46.6353 9.05881 46.6353 11.2246C46.6353 13.324 45.4395 14.7324 43.5394 14.7324C42.4631 14.7324 41.5729 14.2142 41.0946 13.3373V14.6394H40.0316V5.01953H41.0946V9.1784C41.5995 8.27487 42.5429 7.71681 43.659 7.71681ZM43.3534 13.7359C44.6954 13.7359 45.559 12.7659 45.559 11.2246C45.559 9.72317 44.722 8.71335 43.3667 8.71335C42.038 8.71335 41.1212 9.6833 41.1212 11.2246C41.1212 12.7261 41.9848 13.7359 43.3534 13.7359Z"
          fill="white"
        />
        <path
          d="M50.5575 14.7324C48.5777 14.7324 47.1958 13.3771 47.1958 11.2246C47.1958 9.08539 48.5777 7.71681 50.5575 7.71681C52.5505 7.71681 53.9324 9.09867 53.9324 11.2246C53.9324 13.3771 52.5505 14.7324 50.5575 14.7324ZM50.5575 13.7359C51.9128 13.7359 52.8562 12.8058 52.8562 11.2246C52.8562 9.65673 51.8995 8.71335 50.5575 8.71335C49.2288 8.71335 48.2721 9.65673 48.2721 11.2246C48.2721 12.8058 49.2288 13.7359 50.5575 13.7359Z"
          fill="white"
        />
        <path
          d="M57.9168 14.6394H57.0133C55.7908 14.6394 55.2328 14.1345 55.2328 12.8988V8.75321L54.0369 8.76649V7.82311L54.7943 7.8364C55.1796 7.8364 55.3258 7.71681 55.3258 7.33149V5.84333H56.2692V7.8364L57.8769 7.82311V8.76649L56.2692 8.75321V13.0582C56.2692 13.4303 56.4153 13.603 56.8405 13.603H57.9168V14.6394Z"
          fill="white"
        />
        <path
          d="M15.5437 17.911L13.0211 15.5462V9.67594L13.0137 9.69077C12.8683 6.87346 10.5379 4.63384 7.68446 4.63384C4.73726 4.63384 2.34807 7.02302 2.34807 9.97023C2.34807 12.9174 4.73726 15.3066 7.68446 15.3066C9.06408 15.3066 10.3214 14.7831 11.2686 13.9239L12.9804 15.5285C11.6022 16.7738 9.7756 17.532 7.77188 17.532C3.47959 17.532 0 14.0525 0 9.76016C0 5.46787 3.47959 1.98828 7.77188 1.98828C11.9275 1.98828 15.3214 5.24984 15.5333 9.35276L15.5437 9.33182L15.5438 9.76016L15.5437 17.911Z"
          fill="white"
        />
        <path
          d="M12.9804 15.5285L13.0211 15.5666V15.5462L12.9915 15.5185L12.9804 15.5285Z"
          fill="white"
        />
        <path
          d="M4.51978 8.90005C4.51978 8.35217 4.96393 7.90801 5.51182 7.90801C6.0597 7.90801 6.50385 8.35216 6.50385 8.90005V10.1874C6.50385 10.7353 6.0597 11.1795 5.51182 11.1795C4.96393 11.1795 4.51978 10.7353 4.51978 10.1874V8.90005Z"
          fill="white"
        />
        <path
          d="M9.24495 7.90801C8.69707 7.90801 8.25292 8.35217 8.25292 8.90005V10.1874C8.25292 10.7353 8.69707 11.1795 9.24495 11.1795C9.79284 11.1795 10.237 10.7353 10.237 10.1874V8.90005C10.237 8.35216 9.79284 7.90801 9.24495 7.90801Z"
          fill="white"
        />
      </svg>
    );
  },
);
