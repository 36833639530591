import { forwardRef } from 'react';

export const IconEmail = forwardRef<SVGSVGElement>(function CopyContentIcon(
  props,
  ref,
) {
  return (
    <svg
      ref={ref}
      {...props}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.333496H0V0.916693C0.123453 0.916581 0.248657 0.947003 0.364232 1.01121L6 4.14219L11.6358 1.01121C11.7513 0.947003 11.8765 0.916581 12 0.916693V0.333496ZM12 2.5248L6.36423 5.65578C6.13772 5.78162 5.86228 5.78162 5.63577 5.65578L0 2.5248V9.66683H12V2.5248Z"
        fill="currentColor"
      />
    </svg>
  );
});
