import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconLinkedIn = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M22.2234 0.00109863H1.77187C0.792187 0.00109863 0 0.774536 0 1.73079V22.2667C0 23.223 0.792187 24.0011 1.77187 24.0011H22.2234C23.2031 24.0011 24 23.223 24 22.2714V1.73079C24 0.774536 23.2031 0.00109863 22.2234 0.00109863ZM7.12031 20.4527H3.55781V8.99641H7.12031V20.4527ZM5.33906 7.43547C4.19531 7.43547 3.27188 6.51204 3.27188 5.37297C3.27188 4.23391 4.19531 3.31047 5.33906 3.31047C6.47813 3.31047 7.40156 4.23391 7.40156 5.37297C7.40156 6.50735 6.47813 7.43547 5.33906 7.43547ZM20.4516 20.4527H16.8937V14.8839C16.8937 13.5573 16.8703 11.8464 15.0422 11.8464C13.1906 11.8464 12.9094 13.2948 12.9094 14.7902V20.4527H9.35625V8.99641H12.7687V10.562H12.8156C13.2891 9.66204 14.4516 8.71047 16.1813 8.71047C19.7859 8.71047 20.4516 11.0823 20.4516 14.1667V20.4527Z"
          fill="#060534"
        />
      </svg>
    );
  },
);
