import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconFaceBook = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_1124_1433)">
          <path
            d="M24 12.0011C24 5.37368 18.6274 0.00109863 12 0.00109863C5.37258 0.00109863 0 5.37368 0 12.0011C0 17.9906 4.3882 22.9551 10.125 23.8553V15.4698H7.07812V12.0011H10.125V9.35735C10.125 6.34985 11.9166 4.6886 14.6576 4.6886C15.9701 4.6886 17.3438 4.92297 17.3438 4.92297V7.8761H15.8306C14.34 7.8761 13.875 8.80118 13.875 9.7511V12.0011H17.2031L16.6711 15.4698H13.875V23.8553C19.6118 22.9551 24 17.9906 24 12.0011Z"
            fill="#060534"
          />
        </g>
        <defs>
          <clipPath id="clip0_1124_1433">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.00109863)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
