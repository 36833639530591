import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextRoadMap = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="1989"
        height="270"
        viewBox="0 0 1989 270"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M123.32 266.875H70.5469L62.4805 174.453H51.2695V266.875H0V2.32422H65.625C83.5807 2.32422 97.0703 6.79036 106.094 15.7227C115.117 24.6549 119.629 37.3242 119.629 53.7305V115.938C119.629 130.521 115.801 142.279 108.145 151.211L123.32 266.875ZM51.2695 135.215H56.0547C63.7109 135.215 67.5391 129.837 67.5391 119.082V60.293C67.5391 50.3581 63.7109 45.3906 56.0547 45.3906H51.2695V135.215Z"
          fill="#060534"
        />
        <path
          d="M191.953 0C212.279 0 227.546 5.19531 237.754 15.5859C247.962 25.9766 253.066 42.0638 253.066 63.8477V205.215C253.066 247.962 232.695 269.336 191.953 269.336C151.667 269.336 131.523 247.962 131.523 205.215V63.8477C131.523 21.2826 151.667 0 191.953 0ZM200.703 217.93V51.543C200.703 44.6159 197.878 41.1523 192.227 41.1523C186.667 41.1523 183.887 44.6159 183.887 51.543V217.93C183.887 224.857 186.667 228.32 192.227 228.32C197.878 228.32 200.703 224.857 200.703 217.93Z"
          fill="#060534"
        />
        <path
          d="M335.781 266.875L333.047 214.238H311.992L309.258 266.875H256.348L281.777 2.32422H364.355L388.691 266.875H335.781ZM314.316 173.086H331.133L326.348 82.5781L324.023 49.082H321.016L319.102 82.5781L314.316 173.086Z"
          fill="#060534"
        />
        <path
          d="M458.691 2.32422C476.465 2.32422 490.273 7.10938 500.117 16.6797C510.052 26.1589 515.02 40.2865 515.02 59.0625V211.094C515.02 229.414 509.961 243.314 499.844 252.793C489.818 262.181 476.1 266.875 458.691 266.875H395.391V2.32422H458.691ZM462.93 213.145V56.0547C462.93 48.9453 459.922 45.3906 453.906 45.3906H446.66V223.809H453.633C459.831 223.809 462.93 220.254 462.93 213.145Z"
          fill="#060534"
        />
        <path
          d="M617.012 175.82L619.062 128.516L628.223 2.32422H700.82V266.875H652.285L652.559 184.844L655.43 86.8164H652.285L637.793 266.875H592.402L578.457 86.543H575.312L578.184 184.57V266.875H529.922V2.32422H602.793L611.953 128.516L614.277 175.82H617.012Z"
          fill="#060534"
        />
        <path
          d="M786.953 266.875L784.219 214.238H763.164L760.43 266.875H707.52L732.949 2.32422H815.527L839.863 266.875H786.953ZM765.488 173.086H782.305L777.52 82.5781L775.195 49.082H772.188L770.273 82.5781L765.488 173.086Z"
          fill="#060534"
        />
        <path
          d="M913.008 191.27H897.832V266.875H846.562V2.32422H913.008C929.961 2.32422 943.132 7.24609 952.52 17.0898C961.999 26.8424 966.738 40.4688 966.738 57.9688V132.754C966.738 150.345 961.862 164.518 952.109 175.273C942.448 185.938 929.414 191.27 913.008 191.27ZM903.164 147.93C910.638 147.93 914.375 143.145 914.375 133.574V59.6094C914.375 50.1302 910.638 45.3906 903.164 45.3906H897.832V147.93H903.164Z"
          fill="#060534"
        />
        <path
          d="M1145.16 266.875H1092.38L1084.32 174.453H1073.11V266.875H1021.84V2.32422H1087.46C1105.42 2.32422 1118.91 6.79036 1127.93 15.7227C1136.95 24.6549 1141.46 37.3242 1141.46 53.7305V115.938C1141.46 130.521 1137.64 142.279 1129.98 151.211L1145.16 266.875ZM1073.11 135.215H1077.89C1085.55 135.215 1089.38 129.837 1089.38 119.082V60.293C1089.38 50.3581 1085.55 45.3906 1077.89 45.3906H1073.11V135.215Z"
          fill="#060534"
        />
        <path
          d="M1213.79 0C1234.11 0 1249.38 5.19531 1259.59 15.5859C1269.8 25.9766 1274.9 42.0638 1274.9 63.8477V205.215C1274.9 247.962 1254.53 269.336 1213.79 269.336C1173.5 269.336 1153.36 247.962 1153.36 205.215V63.8477C1153.36 21.2826 1173.5 0 1213.79 0ZM1222.54 217.93V51.543C1222.54 44.6159 1219.71 41.1523 1214.06 41.1523C1208.5 41.1523 1205.72 44.6159 1205.72 51.543V217.93C1205.72 224.857 1208.5 228.32 1214.06 228.32C1219.71 228.32 1222.54 224.857 1222.54 217.93Z"
          fill="#060534"
        />
        <path
          d="M1357.62 266.875L1354.88 214.238H1333.83L1331.09 266.875H1278.18L1303.61 2.32422H1386.19L1410.53 266.875H1357.62ZM1336.15 173.086H1352.97L1348.18 82.5781L1345.86 49.082H1342.85L1340.94 82.5781L1336.15 173.086Z"
          fill="#060534"
        />
        <path
          d="M1480.53 2.32422C1498.3 2.32422 1512.11 7.10938 1521.95 16.6797C1531.89 26.1589 1536.86 40.2865 1536.86 59.0625V211.094C1536.86 229.414 1531.8 243.314 1521.68 252.793C1511.65 262.181 1497.94 266.875 1480.53 266.875H1417.23V2.32422H1480.53ZM1484.77 213.145V56.0547C1484.77 48.9453 1481.76 45.3906 1475.74 45.3906H1468.5V223.809H1475.47C1481.67 223.809 1484.77 220.254 1484.77 213.145Z"
          fill="#060534"
        />
        <path
          d="M1638.85 175.82L1640.9 128.516L1650.06 2.32422H1722.66V266.875H1674.12L1674.39 184.844L1677.27 86.8164H1674.12L1659.63 266.875H1614.24L1600.29 86.543H1597.15L1600.02 184.57V266.875H1551.76V2.32422H1624.63L1633.79 128.516L1636.11 175.82H1638.85Z"
          fill="#060534"
        />
        <path
          d="M1808.79 266.875L1806.05 214.238H1785L1782.27 266.875H1729.36L1754.79 2.32422H1837.36L1861.7 266.875H1808.79ZM1787.32 173.086H1804.14L1799.36 82.5781L1797.03 49.082H1794.02L1792.11 82.5781L1787.32 173.086Z"
          fill="#060534"
        />
        <path
          d="M1934.84 191.27H1919.67V266.875H1868.4V2.32422H1934.84C1951.8 2.32422 1964.97 7.24609 1974.36 17.0898C1983.83 26.8424 1988.57 40.4688 1988.57 57.9688V132.754C1988.57 150.345 1983.7 164.518 1973.95 175.273C1964.28 185.938 1951.25 191.27 1934.84 191.27ZM1925 147.93C1932.47 147.93 1936.21 143.145 1936.21 133.574V59.6094C1936.21 50.1302 1932.47 45.3906 1925 45.3906H1919.67V147.93H1925Z"
          fill="#060534"
        />
      </svg>
    );
  },
);
