import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextAVAMedium = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="1171"
        height="700"
        viewBox="0 0 1171 700"
        fill="none"
      >
        <g opacity="0.1">
          <path
            d="M242.993 727.778L234.491 576.237H169.025L160.523 727.778H-3.99121L75.0778 -33.8638H331.84L407.508 727.778H242.993ZM176.252 457.759H228.54L213.661 197.187L206.434 100.752H197.082L191.131 197.187L176.252 457.759Z"
            fill="#FF4DC0"
          />
          <path
            d="M620.484 -33.8638H782.448L704.654 727.778H461.921L384.552 -33.8638H548.217L573.298 429.419L581.375 563.247H591.577L598.804 429.419L620.484 -33.8638Z"
            fill="#FF4DC0"
          />
          <path
            d="M1006.48 727.778L997.975 576.237H932.509L924.007 727.778H759.492L838.562 -33.8638H1095.32L1170.99 727.778H1006.48ZM939.736 457.759H992.023L977.145 197.187L969.918 100.752H960.566L954.614 197.187L939.736 457.759Z"
            fill="#FF4DC0"
          />
        </g>
      </svg>
    );
  },
);
