import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconTwitter = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2174 0.269043H14.4663L9.55298 5.8847L15.3332 13.5264H10.8073L7.26253 8.89174L3.20647 13.5264H0.956125L6.21146 7.51977L0.666504 0.269043H5.30724L8.51143 4.50526L12.2174 0.269043ZM11.428 12.1802H12.6742L4.6301 1.54446H3.29281L11.428 12.1802Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
