import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconTwitterShare = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.933 3.00107H20.9865L14.3154 10.6257L22.1634 21.0011H16.0185L11.2056 14.7085L5.69849 21.0011H2.64311L9.77849 12.8457L2.24988 3.00107H8.5508L12.9013 8.75276L17.933 3.00107ZM16.8613 19.1734H18.5533L7.63142 4.73276H5.81572L16.8613 19.1734Z"
          fill="#060534"
        />
      </svg>
    );
  },
);
