import React from 'react';
import { NextSeo } from 'next-seo';
import { defaultSEO } from 'next-seo.config';
import { Footer, Header } from 'components/layout';
import { getReferralCode, openTelegramWithRef } from '../../utils';
import Script from 'next/script';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}
const Layout = (props: LayoutProps) => {
  const { children, className = '', ...seoProps } = props;
  const SEO = { ...defaultSEO, ...seoProps };

  const handleActionLink = () => {
    const refCode = getReferralCode();
    openTelegramWithRef(refCode);
  };

  return (
    <div className={`page-container ${className}`}>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-S0Z0C7MJ8T" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-S0Z0C7MJ8T');
        `}
      </Script>
      <NextSeo {...SEO} />
      <Header handleActionLink={handleActionLink} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
