import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconLink = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.54668 0.553406L52.5353 0.553406L56.7013 4.71932V54.708H48.3694V14.7768L6.71281 56.4338L0.821289 50.5423L42.478 8.88524H2.54668V0.553406Z"
          fill="#A5E910"
        />
      </svg>
    );
  },
);
