import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const IconTelegram = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.9136 5.20068C5.70219 3.57776 8.22851 2.50783 9.49256 1.9909C13.1017 0.514945 13.8516 0.258556 14.3404 0.25009C14.4479 0.248228 14.6883 0.274424 14.844 0.398649C14.9755 0.503542 15.0117 0.645237 15.029 0.744687C15.0463 0.844137 15.0679 1.07069 15.0507 1.24771C14.8551 3.26817 14.0089 8.17132 13.5783 10.4343C13.3962 11.3918 13.0375 11.7129 12.6902 11.7443C11.9355 11.8126 11.3624 11.2539 10.6314 10.7828C9.48762 10.0456 8.84143 9.5867 7.73116 8.86733C6.44805 8.03598 7.27984 7.57905 8.01108 6.83231C8.20245 6.63688 11.5277 3.66311 11.592 3.39334C11.6001 3.3596 11.6076 3.23384 11.5316 3.16743C11.4556 3.10103 11.3434 3.12373 11.2625 3.14179C11.1478 3.1674 9.32051 4.35487 5.78071 6.70421C5.26205 7.05439 4.79226 7.225 4.37135 7.21606C3.90733 7.20621 3.01473 6.9581 2.35118 6.74603C1.5373 6.48591 0.890452 6.34839 0.946779 5.90663C0.976117 5.67653 1.29839 5.44122 1.9136 5.20068Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
